.control {
  height: 100%;
  background-color: #f7f8fa;
}

.control .footer {
  padding-top: 50px;
}

.control .block {
  background-color: #fff;
  margin: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.control .block .title {
  color: #455a64;
  padding-bottom: 10px;
}

.control .block .filter {
  color: #455a64;
  padding: 10px 0px;
}

.control .block .item {
  padding-top: 10px;
}

.control .block .item span {
  color: #455a64;
}

.control .submit {
  margin: 15px;
}

.control .submit .item {
  margin-bottom: 50px;
}
