html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
:root {
  --rv-blue: #07c160; 
}

.types {
  text-align: center;
  margin-bottom: 15px;
}
