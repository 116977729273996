.setting {
  height: 100%;
  background-color: #f7f8fa;
}

.setting .active {
  display: block;
  padding-bottom: 10px;
}

.setting .hide {
  display: none;
}

.setting .tabs {
  margin: 15px;
}

.setting .tabs .before {
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
  background-color: #fff;
}

.setting .tabs .button {
  margin-top: 25px;
}

.setting .types {
  background-color: #fff;
  padding-bottom: 10px;
}

.setting .types .first {
  margin-right: 50px;
}
