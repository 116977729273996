.overview {
  background-color: #fff;
  margin: 15px;
  padding: 0px 10px;
  border-radius: 5px;
}

.overview .date {
  border-bottom: 1px solid var(--rv-cell-border-color);
}

.overview .rv-grid-item__text {
  color: #323232;
  font-size: 14px;
}

.overview .rv-cell__value {
  color: #323232;
}
