.home {
  height: 100%;
  background-color: #f7f8fa;
}

.home .remain {
  margin: 15px;
  padding: 0px 10px;
  border-radius: 5px;
  background-color: #fff;
}

.home .track {
  margin: 15px;
  padding: 10px 0px;
  border-radius: 5px;
  background-color: #fff;
}

.home .track .track-item-1 .rv-grid-item__content {
  background-color: #f5ebed;
  color: #e3667c;
  border-radius: 5px;
}

.home .track .track-item-2 .rv-grid-item__content {
  background-color: #f3fcf7;
  color: #86c9a5;
  border-radius: 5px;
}

.home .track .track-item-3 .rv-grid-item__content {
  background-color: #f4f9ff;
  color: #74b9e9;
  border-radius: 5px;
}

.home .track .track-item-4 .rv-grid-item__content {
  background-color: #fefdf9;
  color: #ebbc92;
  border-radius: 5px;
}

.home .track .track-item-5 .rv-grid-item__content {
  background-color: #dbf5f54d;
  color: #b7eaeb;
  border-radius: 5px;
}

.home .track .track-item-6 .rv-grid-item__content {
  background-color: #d2d6f933;
  color: #A6AEF3;
  border-radius: 5px;
}

.home .track .rv-grid-item__text {
  color: #323232;
}
