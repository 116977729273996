.recover {
  height: 100%;
  background-color: #f7f8fa;
}

.recover .active {
  display: block;
  padding-bottom: 10px;
}

.recover .hide {
  display: none;
}

.recover .tab {
  margin: 15px;
}

.recover .tabs .button {
  margin-top: 25px;
}

.recover .tabs {
  margin: 15px;
}

.recover .tabs .before {
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
  background-color: #fff;
}

.recover .tabs .upload {
  margin-top: 15px;
}

.recover .types {
  background-color: #fff;
  padding-bottom: 10px;
}

.recover .types .first {
  margin-right: 50px;
}

.recover .track {
  margin-top: 35px;
  padding: 10px 0px;
  border-radius: 5px;
  background-color: #fff;
}

.recover .track .track-item-1 .rv-grid-item__content {
  background-color: #dbf5f54d;
  color: #b7eaeb;
  border-radius: 5px;
}

.recover .track .track-item-2 .rv-grid-item__content {
  background-color: #d2d6f933;
  color: #A6AEF3;
  border-radius: 5px;
}
