.login {
  text-align: center;
}

.login .user {
  margin-top: calc(35vh - 96px);
  margin-left: 50px;
  margin-right: 50px;
}

.login .user .input {
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  margin-bottom: 20px;
}

.login .user .checkbox {
  margin-bottom: 20px;
  float: right;
}

.login .user .input::after {
  border-bottom: none;
}

.login .user .button {
  font-size: 16px;
}
