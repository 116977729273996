.ticketTable {
  height: 100%;
  background-color: #f7f8fa;
}

.ticketTable .date {
  margin-top: 25px;
}

.ticketTable .date .rv-cell__value {
  font-size: 12px;
}

.ticketTable .content {
  background-color: #fff;
}

.ticketTable .content .tips {
  margin-bottom: 15px;
  font-size: 14px;
  padding-left: 16px;
}

.ticketTable .content .title {
  background-color: #07c160;
  color: #fff;
}

.ticketTable .content .rv-loading{
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.ticketTable .content .rv-flexitem {
  text-align: center;
}

.ticketTable .export {
  margin: 15px;
}
