.member {
  height: 100%;
  background-color: #f7f8fa;
}

.member .tabs {
}

.member .tabs .search {
  margin-top: 25px;
  position: relative;
}

.member .tabs .search .search-button {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 0px;
  background: #07c160;
  color: #fff;
}

.member .footer {
  padding-top: 50px;
}

.member .tabs .rv-cell__value {
  font-size: 12px;
}

.member .tabs .content .title {
  background-color: #07c160;
  color: #fff;
}

.member .tabs .content .summation{
  background-color: #07c160;
  color: #fff;
  padding: 6px 0px;
}

.member .tabs .content {
  background-color: #fff;
}

.member .tabs .content .items {
  font-size: 12px;
  margin-left: 3px;
  margin-right: 3px;
}

.member .tabs .content .rv-loading{
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.member .tabs .content .items .item {
  padding-bottom: 6px;
  padding-top: 6px;
}


.member .tabs .content .items .item:nth-child(2n) {
  background-color: #fafafa;
}

.member .tabs .content .rv-flexitem {
  text-align: center;
}
