.clear {
  height: 100%;
  background-color: #f7f8fa;
}

.clear .tabs {
  margin: 15px;
}
.clear .tabs .rv-tabs__wrap {
  border-radius: 5px;
}

.clear .tabs .before {
  margin-top: 25px;
}

.clear .tabs .before .button {
  margin-top: 25px;
}

.clear .tabs .after {
  margin-top: 25px;
}
